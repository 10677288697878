<template>
    <div>
        <b-container class="mt-5 mb-5">
            <div class="mb-2 clearfix">
                <template v-if="!form.canceled_at">
                    <b-button variant="primary" class="float-right">Nieuw ticket</b-button>
                </template>
                <h1 class="fs-4 font-weight-bold mb-0">Tickets</h1>
            </div>
            <EventCanceledAlert :event="form" />
            <b-card class="mb-4">
                <table class="table">
                    <thead class="bg-gray-50 dark:bg-gray-900">
                        <tr>
                            <th>Ticket</th>
                            <th>Verkoop</th>
                            <th>Prijs</th>
                            <th>Limiet</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody wire:sortable="" wire:end.stop="sort($event.target.sortable.toArray())" class="bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-800">
                        <tr v-for="(ticket,key) in form.tickets" :key="key">
                            <td>
                                <div>
                                    <a :href="'/manage/events/' + ticket.event_id + '/settings/tickets/' + ticket.id + '/edit'" class="text-primary text-decoration-none">
                                        {{ ticket.name }}
                                    </a>
                                </div>
                                <div class="text-muted">
                                    <template v-if="ticket.type == 'ticket'">
                                        Toegangsticket
                                    </template>
                                    <template v-else>
                                        Voucher
                                    </template>
                                    <span class="d-md-none">{{ ticket.price|toCurrency }}</span>
                                </div>
                            </td>
                            <td>
                                -
                                <!--
                                <div>
                                    <font-awesome-icon icon="circle" size="xs" class="text-success mr-1" />
                                    Actief
                                </div>
                                <div class="text-muted">
                                    Stopt vandaag 12:00
                                </div>
                                -->
                            </td>
                            <td>
                                <div>
                                    {{ ticket.price|toCurrency }}
                                </div>
                            </td>
                            <td>
                                <template v-if="ticket.amount_available">
                                    {{ ticket.amount_available }}
                                </template>
                                <template v-else>
                                    Geen
                                </template>
                            </td>
                            <td class="text-right">
                                <b-button variant="outline-secondary" size="sm" class="ml-2" :to="'/manage/events/' + ticket.event_id + '/settings/tickets/' + ticket.id + '/edit'">
                                    <font-awesome-icon icon="fa-solid fa-eye"/>
                                </b-button>
                                <!--
                                <b-button variant="secondary" size="sm" class="ml-2">
                                    <font-awesome-icon icon="fa-solid fa-clone"/>
                                </b-button>
                                -->
                                <template v-if="!form.canceled_at">
                                    <b-button variant="outline-secondary" size="sm" class="ml-2" :to="'/manage/events/' + ticket.event_id + '/settings/tickets/' + ticket.id + '/edit'">
                                        <font-awesome-icon icon="fa-solid fa-edit"/>
                                    </b-button>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-card>
            <div class="mb-2 clearfix">
                <template v-if="!form.canceled_at">
                    <b-button variant="primary" class="float-right">Nieuwe categorie</b-button>
                </template>
                <h1 class="fs-4 font-weight-bold mb-0">Categorieën</h1>
            </div>

            <template>
                <b-card class="mb-4" no-body>
                    <b-card-body>
                        <div class="text-center text-muted mt-4 mb-4">
                            <font-awesome-icon icon="users" class="mb-2" size="2x" />
                            <div class="text-dark fs-5">Nog geen categorieën aangemaakt</div>
                            <div>Maak categorieën aan om tickets te tonen op basis van categorie in de webshop. Bijvoorbeeld op dag of datum.</div>
                        </div>
                    </b-card-body>
                </b-card>
            </template>

            <div class="mb-2 clearfix">
                <template v-if="!form.canceled_at">
                    <b-button variant="primary" class="float-right">Nieuwe groep</b-button>
                </template>
                <h1 class="fs-4 font-weight-bold mb-0">Capaciteitsgroepen</h1>
            </div>

            <template>
                <b-card class="mb-4" no-body>
                    <b-card-body>
                        <div class="text-center text-muted mt-4 mb-4">
                            <font-awesome-icon icon="users" class="mb-2" size="2x" />
                            <div class="text-dark fs-5">Nog geen groepen aangemaakt.</div>
                            <div>Je kunt capaciteitsgroepen aanmaken voor een groep met tickets om verkooplimieten op basis van aantallen in te stellen.</div>
                        </div>
                    </b-card-body>
                </b-card>
            </template>

        </b-container>
    </div>
</template>

<script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'
    import EventCanceledAlert from '@/components/event/EventCanceledAlert'

    export default {
        metaInfo: {
            title: 'Tickets',
        },
        components: {
            EventCanceledAlert
        },
        data() {
            return {
                form: {}
            }
        },
        computed: {},
        methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fetchEvent: function() {
                this.setLoading(true);
                this.$axios.get("https://api.tixgo.nl/events/" + this.$route.params.id)
                    .then( response => {
                        this.form = response.data;
                        this.setLoading(false);
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
        },
        created() {
            this.fetchEvent();
        },
    }
</script>